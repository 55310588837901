import React from "react";
import { actions } from "../../../context/actions";
import { useAppContext } from "../../../context";
import { ModalTypes } from "../../Modals/types";
import { Chip } from "../../Chip";
import { ChipsContainer } from ".././styles";
import { useQuery } from "@apollo/client";
import { GET_LOCALIZED } from "../../../queries";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { useParams } from "react-router-dom";
import { contentSquarePageEvent } from "../../../helpers";
import { ReactSVG } from "react-svg";

export const PillFiltersComponent = ({
  filters,
  getCustomizedFilterData,
  squareFilterStyling,
  handleFiltersVisibility
}: {
  filters?: any[];
  getCustomizedFilterData: Function;
  squareFilterStyling?: boolean;
  handleFiltersVisibility: Function;
}) => {
  const {
    value: { productsSorting },
    dispatch,
  } = useAppContext();
  const { storeId = '', harvestId = '' } = useParams();
  const { LanguageVersion, FilterType } = useConfiguration({ storeId, harvestId });
  const { data: translation } = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );
  if (!filters?.[0]) return <></> //check if the filter list doesn't exist or has empty values

  return (
    <>
      <ChipsContainer>
        {!squareFilterStyling &&
          <>
            <Chip
              title={translation?.getLocalization.AllFilters}
              addinitonalIcon={
                <ReactSVG src="/files/generic/img/allFilters.svg" />
              }
              onClick={() => {
                handleFiltersVisibility();
                contentSquarePageEvent('allFilters')
              }}
              isExpand={false}
              background="#DBDBDB"
            />

            <Chip
              title={translation?.getLocalization.SortBy}
              addinitonalIcon={<ReactSVG src="/files/generic/img/sortBy.svg" />}
              colors={productsSorting.length ? 'dark' : 'light'}
              onClick={() => {
                contentSquarePageEvent('sortByFilter')
                dispatch({
                  type: actions.TOGGLE_MODAL,
                  payload: {
                    isModal: true,
                    modalType: ModalTypes.sorting,
                  },
                });
              }}
              isExpand={false}
              background="#DBDBDB"
            />
          </>
        }
        {filters.map((filterElement: any, index: number) => {
          const filterData = getCustomizedFilterData(filterElement);

          return <Chip
            key={filterData.key}
            title={filterData.title}
            colors={filterData.colors}
            onClick={() => {
              filterData.clickHandler();
            }}
            isExpand={false}
            background="#fff"
            squareFilterStyling={squareFilterStyling}
          />
        }
        )}
      </ChipsContainer>
    </>
  );
};
import { useNavigate } from "react-router-dom";
import { CiDeliveryTruck } from "react-icons/ci";
import { MdOutlineArrowForward } from "react-icons/md";

import styled from "@emotion/styled";

export const CartEmptySection = () => {

  return (
    <div>
      <Container>
        <CiDeliveryTruck size={100} fill={"gray"} />
        <Header>Let's start your next order</Header>
        <Message>
          Your pickup cart is empty. Start shopping for your next order.
        </Message>
        <p style={{}} /*onClick={() => navigate(baseUrl)}*/>
          <span>
            Start Your Cart
            <MdOutlineArrowForward style={{ marginLeft: "4px" }} size={24} />
          </span>
        </p>
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  padding: 60px 0px;
  margin-top: 68px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }: any) => theme.palette.backgroundElement};
  color: ${({ theme }: any) => theme.palette.font};

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }: any) => theme.palette.steelblue};
  }
`;

const Header = styled.p`
  font-weight: 500;
  font-size: 18px;
  font-weight: 700;
  font-family: Montserrat;
`;
const Message = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  width: 200px;
  line-height: 1.3;
  font-family: Montserrat;
`;

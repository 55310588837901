import styled from '@emotion/styled';

export const BrandRouteStyled = styled.div<{ theme?: any; }>`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 24px;
  margin-bottom: 60px;
  margin-top: 78px;

  h1 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: ${({ theme }) =>  theme?.palette?.font};
    padding-top: 24px;
    margin-bottom: 24px;
  }

  img {
    width: 100%!important;
  }

  .top {
    width: 100%;
    grid-column: 1 / span 6;

    .grid p {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 15px;

      .single.elem1 { grid-column: 1 / span 12; }

      .bigleft.elem1 { grid-area: 1 / 1 / 3 / 7; }
      .bigleft.elem2 { grid-area: 1 / 7 / 2 / 13; }
      .bigleft.elem3 { grid-area: 2 / 7 / 3 / 13; }

      .bigright.elem1 { grid-area: 1 / 1 / 2 / 7; }
      .bigright.elem2 { grid-area: 2 / 1 / 3 / 7; }
      .bigright.elem3 { grid-area: 1 / 7 / 3 / 13; }
    }
  }

  .bottom {
    width: 100%;
    grid-column: 1 / span 6;

    .grid p {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 15px;

      .single.elem1 { grid-column: 1 / span 12; }

      .bigleft.elem1 { grid-area: 1 / 1 / 3 / 7; }
      .bigleft.elem2 { grid-area: 1 / 7 / 2 / 13; }
      .bigleft.elem3 { grid-area: 2 / 7 / 3 / 13; }

      .bigright.elem1 { grid-area: 1 / 1 / 2 / 7; }
      .bigright.elem2 { grid-area: 2 / 1 / 3 / 7; }
      .bigright.elem3 { grid-area: 1 / 7 / 3 / 13; }
    }
  }

  @media (max-width: 768px) {
    display: block;
    max-width: 100vw;
    padding: 0 10px;
  }

  .brandPage-container {
    grid-column: '1 / span 6';
    margin-top: 24px;
    width: 100%;

    @media (max-width: 1069px) {
      grid-column: 1 / span 6;
    }
  }

  }
  .brandProducts {
    grid-column: 1 / span 6;
    width: 100%;
    margin-top: 24px;
  }

  .product-slider-container {
    grid-column: 1 / span 6;
    width: 100%;
  }
`;

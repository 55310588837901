import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_CATEGORY_BY_TERM } from '../../queries';
import { useAppContext } from '../../context';
import { IProduct } from '../../types';
import { useFilters } from './useFilters';
import {
  FiltersStyled,
  Header,
  PopularFiltersStyled,
} from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import 'swiper/css';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { useConfiguration } from '../../hooks/useConfiguration';
import { useTheme } from '@mui/material';
import { PillFiltersComponent } from './PillFilters';
import { PillFiltersWithImageComponent } from './PillFiltersWithImage';

export const PopularFilters = ({
  products,
  categories,
  brands,
  productsBeforeFiltering
}: {
  products: IProduct[];
  productsBeforeFiltering: IProduct[];
  categories: any;
  brands: string[];
}) => {
  const {
    value: { searchFilters },
  } = useAppContext();

  const { translation, handleFiltersVisibility, getCustomizedFilterData } = useFilters(
    {
      products,
      productsBeforeFiltering,
    }
  );
  const muiTheme = useTheme();

  const navigate = useNavigate();

  const { storeId = '', termId, harvestId = '' } = useParams();

  const { FilterType,PillFiltersWithImageDisplayStyle } = useConfiguration({ storeId, harvestId });

  const { data, loading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: termId?.split('__')[0],
    })
  );

  const edges = data?.getEPogTermListing?.edges;

  let filters = edges
    .map((edge: any) => edge?.node?.PillFilters)
    .flat()
  let filtersWithImage = edges
    .map((edge: any) => edge?.node?.PillFiltersWithImage)
    .flat();
  const pillFiltersNotEmpty = filters?.[0] || filtersWithImage?.[0]

  return (
    <FiltersStyled>
      {(products?.length || (!products?.length && searchFilters?.length)) && pillFiltersNotEmpty && FilterType ? <Header theme={muiTheme}>{translation?.getLocalization.PopularFilters}</Header> : ''}
      <PopularFiltersStyled isLessThanSixItems={categories?.length}>
        {categories?.map((category: any, index: number) => (
          (category?.TermImage?.fullpath) ? 
          <div
            className="filter"
            key={index}
            onClick={() => 
              navigate(
                `/${storeId}/${harvestId}/category/${
                  category.id
                }__${category.key.replaceAll(' ', '_')}`
              )
            }
          >
            <img
              src={
                category?.TermImage?.fullpath
                  ? `${imgSrcByEnv()}${category?.TermImage?.fullpath}`
                  : '/files/generic/img/square.svg'
              }
              alt="carousel"
            />
            {/* <img src="/files/generic/img/square.svg" alt="" /> */}
            <p> {category?.key}</p>
          </div> : ''
        ))}
      </PopularFiltersStyled>

      {(products.length || (!products?.length && searchFilters?.length)) &&
        (FilterType == "Plain" || FilterType == "All") &&
        <PillFiltersComponent filters={filters} getCustomizedFilterData={getCustomizedFilterData} handleFiltersVisibility={handleFiltersVisibility}/>
      }
      {(products.length || (!products?.length && searchFilters?.length)) &&
        (FilterType == "Image" || FilterType == "All") &&
        (PillFiltersWithImageDisplayStyle === "WithoutImages" ?
          <PillFiltersComponent filters={filtersWithImage} getCustomizedFilterData={getCustomizedFilterData} squareFilterStyling={true} handleFiltersVisibility={handleFiltersVisibility}/> :
          <PillFiltersWithImageComponent filters={filtersWithImage} getCustomizedFilterData={getCustomizedFilterData} />
        )
      }
      {/* <ChipsContainer>
        {brands?.length > 1 &&
          brands?.map((brand: string) => {
            const isChecked = brandFilter?.brands[brand] || false;
            return (
              <Chip
                key={brand}
                title={brand}
                onClick={() => {
                  contentSquarePageEvent('brandFilter_' + brand)
                  dispatch({
                    type: actions.ADD_SEARCH_FILTER,
                    payload: {
                      name: 'Brand',
                      brands: { ...brandFilter?.brands, [brand]: !isChecked },
                    },
                  });
                }}
                colors={isChecked ? 'dark' : 'light'}
                isExpand={false}
                background="#ffffff"
              />
            );
          })}
      </ChipsContainer>   */}
      
      {/* <ChipsContainer>
        {
          brands?.length > 1 &&
          brands?.map((brand: string) => {
            const isChecked = brandFilter?.brands[brand] || false;
            return (
              <ChipWithImage
                key={brand}
                title={brand}
                backgroundImage={imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath}
                onClick={() => {
                  contentSquarePageEvent('brandFilter_' + brand)
                  dispatch({
                    type: actions.ADD_SEARCH_FILTER,
                    payload: {
                      name: 'Brand',
                      brands: { ...brandFilter?.brands, [brand]: !isChecked },
                    },
                  });
                }}
                colors={isChecked ? 'dark' : 'light'}
                isExpand={false}
                background="#ffffff"
              />
            );
          })
        }
      </ChipsContainer> */}
    </FiltersStyled>
  );
      
};

import React from "react";
import { ChipsContainer } from ".././styles";
import { ChipWithImage } from "../../ChipWithImage";
import { imgSrcByEnv } from "../../../../config/imgSrcByEnv";

export const PillFiltersWithImageComponent = ({
  filters,
  getCustomizedFilterData,
}: {
  filters?: any[];
  getCustomizedFilterData: Function;
}) => {
  if (!filters?.[0]) return <></> //check if the filter list doesn't exist or has empty values

  return (
    <>
      <ChipsContainer>
        {filters.map((filterElement: any, index: number) => {
          const filterData = getCustomizedFilterData(filterElement);

          return <ChipWithImage
            key={filterData.key}
            title={filterData.title}
            colors={filterData.colors}
            backgroundImage={imgSrcByEnv() + filterElement.Thumbnail?.fullpath}
            onClick={() => {
              filterData.clickHandler();
            }}
            isExpand={false}
            background="#ffffff"
          />
        }
        )}
      </ChipsContainer>
    </>
  );
};